body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:rgba(255,255,255,0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tharaldsen #opt .col-12 {
  border: #eeae33 3px solid;
  padding:25px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(238,174,51,1) 100%);
  border-radius:25px;
  margin-top:25px;
  text-align:center;
}

.digi #opt .col-12 {
    border: #d61525 3px solid;
    padding:25px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(214, 21, 37,1) 100%);
    border-radius:25px;
    margin-top:25px;
    text-align:center;
}

.ctsform #opt .col-12 {
    border: #d61525 3px solid;
    padding:25px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(214, 21, 37,1) 100%);
    border-radius:25px;
    margin-top:0px;
    text-align:center;
}

.bauform #opt .col-12 {
    border: #f6e594 3px solid;
    padding:25px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(42, 26, 73, 1) 100%);
    border-radius:25px;
    margin-top:0px;
    text-align:center;
}

.buyform #opt .col-12 {
    border: #000000 3px solid;
    padding:25px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgb(105, 158, 226) 100%);
    border-radius:25px;
    margin-top:0px;
    text-align:center;
}

.kwbuyform #opt .col-12 {
    border: #000000 3px solid;
    padding:25px;
    background:rgb(214,21,37,1);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(214, 21, 37,1) 100%);
    border-radius:25px;
    margin-top:0px;
    text-align:center;
}

.rogbuyform #opt .col-12 h5, .rogbuyform #opt .col-12 h3 {
    color:#0033ff;
}

.umansellform .form-label {
    font-family: 'Roboto', sans-serif;
}

.umansellform .form-control {
    border:none;
    border-bottom:#000000 1px solid;
    border-radius:0;
}

.umansellform .btn {
    background-color:rgba(0,0,0,0);
    color:#000000;
    border-radius:0;
    border:#000000 2px solid!important;
    padding:15px;
    font-weight:bold;
}

.umansellform .btn:hover {
    background-color:#ee0000;
}

.noborder {
    border:none!important;
    border-radius:none!important;
    padding:none!important;
}

.rogbuyform #opt .col-12 {
    padding:25px;
    background: #552448;
    border-radius:25px;
    margin-top:0px;
    text-align:center;
}

.godbuyform #opt .col-12 h5, .godbuyform #opt .col-12 h3, .bogbuyform #opt .col-12 h5, .bogbuyform #opt .col-12 h3 {
    color:#fefefe;
    text-shadow:#000000 0px 0px 2px;
}

.godbuyform #opt .col-12 {
    border: #fefefe 3px solid;
    padding:25px;
    background: #552448;
    border-radius:25px;
    margin-top:0px;
    text-align:center;
    color: #fefefe;
}

.bogbuyform #opt .col-12 {
    border: #fefefe 3px solid;
    padding:25px;
    background: #202020;
    border-radius:25px;
    margin-top:0px;
    text-align:center;
    color: #fefefe;
}

.dsmithform #opt .col-12 {
    border: #fefefe 3px solid;
    padding:25px;
    background: #355730;
    border-radius:25px;
    margin-top:0px;
    text-align:center;
    color: #fefefe;
}

.kernbuyform #opt .col-12 {
    border: #000000 3px solid;
    padding:25px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgb(53,87,48) 100%);
    border-radius:25px;
    margin-top:0px;
    text-align:center;
}

.waterform #opt .col-12 {
    border: #0000ff 3px solid;
    padding:25px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(34, 172, 239,1) 100%);
    border-radius:25px;
    margin-top:0px;
    text-align:center;
}

.grossform #opt .col-12 {
    border: #007700 3px solid;
    padding:25px;
    background: rgb(17,160,16);
    background: linear-gradient(0deg, rgba(255,255,255,1) 60%, rgba(17,160,16,1) 100%);
    border-radius:25px;
    margin-top:0px;
    text-align:center;
}

.megform #opt .col-12 {
    border: goldenrod 3px solid;
    padding:25px;
    background: #353535;
    border-radius:25px;
    margin-top:0px;
    text-align:center;
}

.megform #opt .col-12 h2 {
    color:goldenrod
}

.brazform #opt .col-12 {
    box-shadow:#000000 0px 0px 10px;
    padding:25px;
    background: rgb(110, 193, 228);
    background: linear-gradient(180deg, rgba(110, 193, 228,1) 0%, rgb(255, 255, 255) 100%);
    border-radius:25px;
    margin-top:0px;
    text-align:center;
}

.wolfeform #opt .col-12 {
    box-shadow:#000000 0px 0px 10px;
    padding:25px;
    background: rgba(193,22,162,1);
    background: linear-gradient(180deg, rgba(193,22,162,1) 0%, rgb(255, 255, 255) 100%);
    border-radius:25px;
    margin-top:0px;
    text-align:center;
}

.solarform #opt .col-12 {
    border: #20c2f1 3px solid;
    padding:25px;
    border-radius:25px;
    margin-top:0px;
    text-align:center;
    box-shadow:#000000 0px 0px 10px;
}

.ardform #opt .col-12 {
    padding:25px;
    margin-top:0px;
    text-align:center;
}

.weisssellform .form-label {
    font-family: 'Roboto', sans-serif;
}

.weisssellform .form-control {
    border:none;
    border-bottom:#000000 1px solid;
    border-radius:0;
}

.weisssellform .btn {
    background-color:rgba(0,0,0,0);
    color:#000000;
    border-radius:0;
    border:#000000 2px solid!important;
    padding:15px;
    font-weight:bold;
}

.weisssellform .btn:hover {
    background-color:#1e9c33;
}

.wattssellform .form-label {
    font-family: 'Roboto', sans-serif;
}

.wattssellform .form-control {
    border:none;
    border-bottom:#000000 1px solid;
    border-radius:0;
}

.wattssellform .btn {
    background-color:rgba(0,0,0,0);
    color:#000000;
    border-radius:0;
    border:#000000 2px solid!important;
    padding:15px;
    font-weight:bold;
}

.wattssellform .btn:hover {
    background-color:#d2a747;
}

.floydsellform .form-label {
    font-family: 'Montserrat', sans-serif;
}

.floydsellform .form-control {
    border:none;
    border-bottom:#000000 1px solid;
    border-radius:0;
}

.floydsellform .btn {
    background-color:rgba(0,0,0,0);
    color:#000000;
    border-radius:0;
    border:#000000 2px solid!important;
    padding:15px;
    font-weight:bold;
}

.floydsellform .btn:hover {
    background-color:#39603d;
}

.drkmode {
    background-color:#121212!important;
}

.kulgsellform {
    background-color:#121212;
    padding:25px;
}

.kulgsellform #opt {
    max-width:100%!important;
}

.kulgsellform .form-label {
    font-family: 'Montserrat', sans-serif;
}

.kulgsellform h3, .kulgsellform h5 {
    color:#fefefe!important;
}

.kulgsellform .form-control {
    border:none;
    border-bottom:#dcdcdc 1px solid;
    border-radius:0;
}

.kulgsellform .btn {
    background-color:rgba(0,0,0,0);
    color:#dcdcdc;
    border-radius:0;
    border:#dcdcdc 2px solid!important;
    padding:15px;
    font-weight:bold;
}

.kulgsellform .btn:hover {
    background-color:red;
}

.monteform .form-control {
    border:none;
    border-bottom:#000000 1px solid;
    border-radius:0;
}

.monteform .btn {
    background-color:rgba(0,0,0,0);
    color:#000000;
    border-radius:0;
    border:#000000 2px solid!important;
    padding:15px;
    font-weight:bold;
}

.monteform .btn:hover {
    background-color:rgb(68, 25, 117);
}

.manform .form-control {
    border-radius:5px;
}

.manform .col-12 h2 {
    font-size:20px;
}

.manform .btn {
    background-color:#002b49;
    color:#fefefe;
    border-radius:5px;
    padding:5px;
    font-weight:bold;
}

.manform .btn:hover {
    background-color:rgb(0, 173, 239);
}

#opt .col-12 h2 {
  margin-bottom:25px;
}

#opt .col-12 button {
  width:100%;
}

.turnq button {
    border:#000000 1px solid;
}

.succeed {
    margin:auto;
}

.successmsg {
    color:#efefef;
}


    html, body {
        margin:0;
        height: 100%;
    }

    body {
      height:100%;
    }

    h1, h2, h3 {
        font-family:'Alata', serif;
    }

    h4, h5, h6, a, p, li {
        font-family:'Open Sans', sans-serif;
    }

    ul, li {
        list-style:none;
    }

    a {
        color:#333333;
    }

    a:hover {
        color:#2222cc;
    }

    iframe {
      width:100%;
    }

    #name {
        text-align:center;
    }

    #name h1 {
        color:#ffffff;
        text-shadow:#333333 1px 1px 3px;
    }

    #logo img {
        max-height:65px;
        margin-top:5px;
    }

    header .container .row {
        height:150px;
    }

    #necklace {
      margin-bottom:25px;
      width:1134px;
      margin-left:-10px;
      margin-top:-10px;
      border-top-left-radius:8px;
      border-top-right-radius:8px;
      padding-bottom:10px;
      border-bottom:#eeae33 3px solid;
      background-size:cover;
    }

    #name h2 {
        color:#efefef;
        font-size:24px;
        margin-top:20px;
        text-shadow:#333333 1px 1px 2px;
    }

    #externals, #related {
        border-bottom: #2222cc 1px solid;
        text-align:center;
    }

    #externals li, #related li {
        margin-bottom:20px;
        margin-right:25px;
        display:inline;
    }

    #socials li img {
        max-width:50px;
        margin-bottom:20px;
        margin-left:10px;
    }

    #socials ul li {
        display:inline-block;
        margin-top:50px;
    }

    #maintext .container .col-4 {
        text-align:center;
    }

#maintext .row {
    margin-bottom: 15px;
}

    .biopic {
      max-width:100%;
    }

    #upperlinks .nav {
        margin-top:50px;
    }

    #upperlinks .nav a {
        font-size:14px;
    }

    #maintext h1 {
      text-transform: capitalize;
      font-size:34px;
    }

    .wrapper {
      background:rgba(245,245,245,0.8);
      margin-top:25px;
      margin-bottom:25px;
      border-radius:10px;
      border:#eeae33 3px solid;
      padding:10px;
    }

    .nav {
        margin:auto;
        margin-top:30px;
        margin-bottom:15px;
    }

    .nav a {
        color:#333333;
        font-weight:bold;
    }

    .nav a:hover {
        color:#2222cc;
    }

    #contentbox img {
        max-width:100%;
        margin:10px 0 25px;
    }

    .card-body {
        background:#eeeeee;
        font-size:14px;
        line-height:15px;
        padding:10px;
    }

    .card-body h6 {
        font-weight:bold;
        font-size:14px;
    }

    .divider {
        background: #2222cc85;
        height:25px;
        margin:25px 0px;
        width:100%;
    }

    #linksvid .col-3 li {
        margin-bottom:25px;
    }

    #linksvid .col-3 li a {
        font-weight:bold;
    }

    footer {
        width:100%;
        height:80px;
        background:#cccccc;
        padding-top:5px;
        margin-top:25px;
    }

    footer h5 {
        margin-top:25px;
    }

    @media only screen and (max-width:425px){
        #wrapper #name {display:none;}
        #name {display:none}
        #logo {display:none;}
        #necklace {max-width:100%;border-bottom:none;}
        #necklace .col-9 {max-width:100% !important; width:100%; flex:0 0 100%;}
        #necklace .col-9 .nav-item {margin:0 !important;width:100%;}
        #necklace .col-9 .nav-item a {margin:0; font-size:16px; line-height:1.5;}
        .nav-link {padding:5px 5px 15px;}
        .nav a {font-size:16px;font-weight:normal;}
        #linklist {display:none;}
        #maintext h1 {font-size:24px;}
        #maintext h4 {font-size:18px;}
        #maintext p {font-size:16px;}
        #phrases .card .card-body a h6 {text-align:center;}
        #phrases .card .card-body p {text-align:center;}
        .card-text {display:none;}
        #name {text-align:center;}
        #maintext {text-align:center;}
    }
